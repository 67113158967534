import React from 'react';

const Logo = ({ width, height}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="40" viewBox="0 0 200 40">
        <defs>
            <clipPath id="clip-Web_1920_1">
            <rect width="200" height="40"/>
            </clipPath>
        </defs>
        <g id="Web_1920_1" data-name="Web 1920 – 1" clip-path="url(#clip-Web_1920_1)">
            <rect width="200" height="40" fill="#fff"/>
            <g id="Group_1" data-name="Group 1" transform="translate(-557.751 -408)">
            <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="8.612" cy="8.612" rx="8.612" ry="8.612" transform="translate(561.269 422.869)" fill="#e32020"/>
            <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="8.612" cy="8.612" rx="8.612" ry="8.612" transform="translate(561.315 414.171)" fill="#3ee320"/>
            <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="8.612" cy="8.612" rx="8.612" ry="8.612" transform="translate(569.759 418.37)" fill="#2036e3"/>
            <text id="ExactEvent" transform="translate(590.883 437)" font-size="26" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="0" y="0">ExactEvent</tspan></text>
            </g>
        </g>
        </svg>
    )
}

export default Logo;