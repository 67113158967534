import React from 'react';
import Logo from '../Logo';
import './index.css';

const Header = ({ children }) => {
    return (
        <header className="header">
            <div className="content-container">
                <div className="header__content">
                    <Logo width={200} height={90} />
                </div>
            </div>
        </header>
    )
}

export default Header;