import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MapPage from '../Pages/MapPage';
import SubmitPage from '../Pages/SubmitPage';
import NotFoundPage from '../Pages/NotFoundPage';


export const history = createBrowserHistory();

const AppRouter = () => (
    <Router history={history}>
        <div>
            <Switch>
            <Route path="/" component={MapPage} exact={true}/>
            <Route path="/submit" component={SubmitPage} exact={true}/>
            <Route  component={NotFoundPage} />
        </Switch>
        </div>        
    </Router>
);

export default AppRouter;