import React from 'react';
import MapView from '../Map';
import Header from '../Header';

const MapPage = () => {
    return (
        <div>
            <Header />
            <MapView />
        </div>
    )

};

export default MapPage;