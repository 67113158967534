import React, { useRef, useEffect} from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './index.css';
import StudioData from '../../api/studioData.json';

mapboxgl.accessToken = "pk.eyJ1IjoiZXh0cmVtZS1pbXBhY3QiLCJhIjoiY2tqeXR5d2k5MDE0OTJucGY3eW1qd3F1YiJ9.eAb3aW8m_UgACxvE30nhww";

const MapView = () => {
    const mapContainerRef = useRef(null);
    const studioData = StudioData;

    useEffect(() => {
        const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/outdoors-v11",
        center: [-104.855, 39.78674],
        zoom: 3
        });

        map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

        map.addControl(new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl
            })
        );

        map.on("load", () => {
            studioData.features.forEach((marker) => (
                new mapboxgl.Marker(marker)
                    .setLngLat(marker.geometry.coordinates)
                    .setPopup(new mapboxgl.Popup({ offset: 25 })
                        .setHTML(
                            '<h3>' + marker.properties.title + '</h3><p>' 
                            + marker.properties.address1 + ' ' + marker.properties.address2 + '</p><p>'
                            + marker.properties.city + ', ' + marker.properties.state + ' ' + marker.properties.postalCode + '</p><a href={marker.properties.website}>'
                            + marker.properties.website + '</a><p>'
                            + marker.properties.phone + '</p>')))
                    .addTo(map)
            );
        });

        return () => map.remove();
        
    }, [studioData.features]);

    return <div className="mapContainer" ref={mapContainerRef} />;
    };

export default MapView;